import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getTagsAsync = createAsyncThunk('auth/getTagsIn',async (data,thunkAPI) => {
    try{
        
        const res = await axios(data);
   
     return { isError : false, isLoading : false,isFetching : true, data : res.data.data, count : res.data.count ,errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, isFetching:false ,data : [], count : 0, errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const getTagsSlice = createSlice({
    name : 'getTags',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        isFetching: false,
        data: [], 
        count : 0
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.data = [];
            state.isFetching = false;
            state.count = 0
        }
    },
    extraReducers: {
        [getTagsAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [getTagsAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,data,count,isFetching } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.data = data;
            state.count = count;
            state.isFetching = isFetching;
        },
        [getTagsAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const getTagsSelector = (state) => state.reducer.getTags;
export default getTagsSlice.reducer;
export const { data , clearAuthState } = getTagsSlice.actions;
import Storage from './storage';

class User {
  constructor() {
    this.storage = new Storage();
  }

  isAuthenticated = () => {
    const userToken = this.getUserToken();
    if (!Object.keys(userToken).length || userToken === '{}') {
      return false;
    }

    return true;
  };

  getUserToken = () => this.storage.get('token');

  parseJwt = () => {
    try {
      return JSON.parse(atob(this.storage.get('token').split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  signOut = () => {
    this.userManager.signoutRedirect();
  };

  forceLogout = () => {
    this.storage.delete('token');
    window.location = '/signin';
  };
}

export default User;

import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getParentCategoryAsync = createAsyncThunk('auth/getParentCategoryIn',async (data,thunkAPI) => {
    try{
        console.log(data);
     const res = await axios(data);
     console.log(data);
     return { isError : false, isLoading : false,isFetching : true, data : res.data.data, errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, isFetching:false ,data : [], errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const getParentCategorySlice = createSlice({
    name : 'getParentCategory',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        isFetching: false,
        data: []
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.data = [];
            state.isFetching = false;
        }
    },
    extraReducers: {
        [getParentCategoryAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [getParentCategoryAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,data,isFetching } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.data = data;
            state.isFetching = isFetching;
        },
        [getParentCategoryAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const getParentCategorySelector = (state) => state.reducer.getParentCategory;
export default getParentCategorySlice.reducer;
export const { data , clearAuthState } = getParentCategorySlice.actions;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import User from '../utils/user';

const { isAuthenticated } = new User();
const PrivateRoutes = ({ component: Component,...rest }) => {
  const userLoggedIn = isAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) =>
        userLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoutes;
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import * as toastr from 'toastr';

const firebaseConfig = {
    apiKey: "AIzaSyDhk3vOhnKSRPIBa5zOuJHR6QBvopK10-k",
    authDomain: "livbay.firebaseapp.com",
    projectId: "livbay",
    storageBucket: "livbay.appspot.com",
    messagingSenderId: "161101899806",
    appId: "1:161101899806:web:29e0847f7654e52038660f",
    measurementId: "G-52DT0N6DXB"
};
  
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFToken = async () => {
    try{
        return await getToken(messaging, {vapidKey: 'BHzb2TLAtFv0nl5WJPjKeHffXFhf52Dl-2t0jNv3mrIR1neYFIliGBScMRYZ6K9EBoD7yZhG09aZGsbic2iPSZo'});
    }catch(e){
        return null;
    }
}

export const onMessageListener = () =>{
    try{
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                const {notification:{body,title}} = payload;
                if(body && title){
                    toastr.success(body,title);
                }
            });
        });
        return true;
    }catch(e){
        return true;
    }
};
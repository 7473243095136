import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProductsAsync = createAsyncThunk('auth/getProductsIn',async (data,thunkAPI) => {
    try{
     const res = await axios(data);
   
     return { isError : false, isLoading : false,isFetching : true, data : res.data.data,count:res.data.count, errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, isFetching:false ,data : [], count:0,errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const getProductsSlice = createSlice({
    name : 'getProducts',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        isFetching: false,
        data: [],
        count:0
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.data = [];
            state.isFetching = false;
            state.count=0;
        }
    },
    extraReducers: {
        [getProductsAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [getProductsAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,data,count,isFetching } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.data = data;
            state.isFetching = isFetching;
            state.count= count;
        },
        [getProductsAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const getProductsSelector = (state) => state.reducer.getProducts;
export default getProductsSlice.reducer;
export const { data , clearAuthState } = getProductsSlice.actions;
import { combineReducers } from "redux";
import signInAuth from './../pages/login/signin-slice';
import getCategory from './../pages/catalog/categories/getcategories-slice'; 
import getProducts from './../pages/catalog/products/getproducts-slice';
import getSizes from './../pages/catalog/sizes/getsizes-slice';
import updateProductStatus from './../pages/catalog/products/updatestatus-slice';
import approveProduct from './../pages/catalog/products/approveproducts-slice';
import getColor from './../pages/catalog/colors/getcolor-slice';
import addColor from './../pages/catalog/colors/addcolor-slice';
import getParentCategory from "./../pages/catalog/categories/getparentcategory-slice";
import getTags from "./../pages/catalog/tags/gettags-slice";
import addTag from "./../pages/catalog/tags/addtag-slice";
import updateTag from "./../pages/catalog/tags/updatetag-slice";

export const reducer = combineReducers({
  signInAuth          : signInAuth,
  getCategory         : getCategory,
  getProducts         : getProducts,
  getSizes            : getSizes,
  updateProductStatus : updateProductStatus,
  approveProduct      : approveProduct,
  getColor            : getColor,
  addColor            : addColor,
  getParentCategory   : getParentCategory,
  getTags             : getTags,
  addTag              : addTag,
  updateTag           : updateTag
});
import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Storage from "../../utils/storage";

const storage = new Storage();

export const signInAsync = createAsyncThunk('auth/signIn',async (data,thunkAPI) => {
    try{
     const res = await axios(data);
     storage.save('token', res.data.data._token);
     return { isError : false, isLoading : false, signInSuccess : true, errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, signInSuccess : false, errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const signInSlice = createSlice({
    name : 'signInAuth',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        signInSuccess: false
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.signInSuccess = false;
        }
    },
    extraReducers: {
        [signInAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [signInAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,signInSuccess } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.signInSuccess = signInSuccess;
        },
        [signInAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const signInSelector = (state) => state.reducer.signInAuth;
export default signInSlice.reducer;
export const { signInSuccess , clearAuthState } = signInSlice.actions;